import { Button, CustomBadge, RootContainer } from "@/components"
import "./QuoteDetailPage.scss"
import { useLazyGetInvoiceQuery } from "@/services/apiDigifabster/order"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useEffect, useMemo } from "react"
import { useAppLoading } from "@/hooks/useLoading"
import { useSelector } from "react-redux"
import { IQuoteStore } from "@/store/quote"
import { RootState } from "@/store"
import { format } from "date-fns"
import QuoteItem from "./components/QuoteItem/QuoteItem"
import QuoteSummary from "./components/QuoteSummary/QuoteSummary"
import {
  BadgeColor,
  BadgeType,
  ButtonHierarchy,
  Size,
  Type
} from "@/enums/common.enum"
import { DownloadIcon, NotesIcon, quoteForgeLabIcon } from "@/assets"
import { EOrderStatus } from "@/enums/quotesList.enum"
import {
  useGetAdditionalFileQuery,
  useLazyGetQuoteDetailQuery
} from "@/services/apiDigifabster/quote"
import { IAuthStore } from "@/store/auth"
import {
  calcRangeDateAfter7Days,
  formatRangeDeliveryDate,
  getDiffDeliveryDays,
  getLatestDeliveryDate,
  getLatestLeadTimeForUser
} from "@/utils/timeFormatHelper"
import { getTextShippingMethod, priceDisplay } from "@/utils/functionHelper"
import { safeConcatUrl } from "@/utils/stringHelper"
import {
  DIGIFABSTER_INVOICE_URL,
  PHONE_NUMBER_CONTACT
} from "@/constants/common.constant"
import { EDeliveryCarrier, EDeliveryTime } from "@/enums/shipping.enums"
import { MANUAL_REQUEST_MATERIAL_ID } from "@/constants/order.constrant"

function QuoteDetailPage() {
  const { invoiceId, invoice_hash } = useParams()
  const [getInvoiceDetail, { isFetching, isError, data }] =
    useLazyGetInvoiceQuery()
  const [getOrderDetail, { isFetching: isFetchingOrder, data: orderData }] =
    useLazyGetQuoteDetailQuery()
  const { invoiceDetail, quoteDetail } = useSelector<RootState, IQuoteStore>(
    (s) => s.quote
  )
  const orderId = invoiceDetail?.order?.id
  const { data: fileData, isFetching: isFetchingFile } =
    useGetAdditionalFileQuery(
      { quoteId: String(invoiceDetail?.order?.id) },
      { skip: !orderId }
    )

  const { isAuthenciated } = useSelector<RootState, IAuthStore>(
    (state) => state.auth
  )
  const { pathname, search } = useLocation()
  const currency = "$"

  const navigate = useNavigate()

  useEffect(() => {
    initPage()
  }, [])

  const initPage = async () => {
    if (invoiceId && invoice_hash) {
      const { data: res } = await getInvoiceDetail({
        id: invoiceId,
        hash: invoice_hash
      })

      await getOrderDetail({ quoteId: String(res?.order?.id) })

      if (
        ![
          EOrderStatus.FIRM_OFFER_SENT,
          EOrderStatus.WAITING_FOR_REVIEW,
          EOrderStatus.CANCEL_BY_ADMIN,
          EOrderStatus.CANCEL_BY_USER
        ].includes(res?.order?.status as EOrderStatus)
      ) {
        navigate(`/orders/invoices/${invoiceId}/${invoice_hash}`)
      }
    }
  }
  const waitingPrice = useMemo(() => {
    return (
      quoteDetail?.line_items?.reduce((acc, item) => {
        if (
          item.status === EOrderStatus.WAITING_FOR_REVIEW ||
          item.material === MANUAL_REQUEST_MATERIAL_ID
        ) {
          return acc + item.total_price
        }
        return acc
      }, 0) || 0
    )
  }, [quoteDetail])
  useEffect(() => {
    if (isError) {
      return navigate(`/not-found`)
    }
  }, [isError])

  useAppLoading([isFetching, isFetchingFile, isFetchingOrder])

  const product = quoteDetail?.line_items
  const order = quoteDetail
  const createdAt = order?.date
    ? format(new Date(order.date), "MMMM do, yyyy")
    : ""
  // const additionalContact =
  //   order?.additional_billing_contacts?.length > 0
  //     ? order.additional_billing_contacts?.[0]
  //     : ""

  const range = useMemo(() => {
    return formatRangeDeliveryDate(
      getDiffDeliveryDays(getLatestLeadTimeForUser(quoteDetail?.line_items)),
      getLatestDeliveryDate(quoteDetail?.line_items),
      new Date(quoteDetail?.date || "")
    )
  }, [quoteDetail?.delivery_time, quoteDetail?.line_items])

  const hideBillingAddress = useMemo(() => {
    return (
      order?.billing_address?.company_name ||
      order?.billing_address?.street_address ||
      order?.billing_address?.apartment ||
      order?.billing_address?.city ||
      order?.billing_address?.state ||
      order?.billing_address?.country ||
      order?.billing_address?.postcode
    )
  }, [order?.billing_address])

  const getColorBagde = (status: string) => {
    if (status === EOrderStatus.WAITING_FOR_REVIEW) return BadgeColor.INPROGRESS
    if (status === EOrderStatus.FIRM_OFFER_SENT) return BadgeColor.SUCCESS
    if (status === EOrderStatus.CANCEL_BY_ADMIN) return BadgeColor.ERROR
    return BadgeColor.ROLE
  }

  const deliveryTypeText = (str: string) => {
    const convertStr = str ? str.replace(str[0], str[0].toUpperCase()) : ""
    if (str === EDeliveryCarrier.FEDEX)
      return `${convertStr} ${EDeliveryTime.FEDEX}`
    if (str === EDeliveryCarrier.UPS)
      return `${str.toUpperCase()} ${EDeliveryTime.UPS}`
    if (str === EDeliveryCarrier.PUROLATOR)
      return `${convertStr} ${EDeliveryTime.PUROLATOR}`
    return "Pick up"
  }

  const handleBack = () => {
    if (pathname.includes("quotes")) {
      const params = new URLSearchParams(search)
      const page = params.get("page")
      const limit = params.get("limit")

      return navigate(`/quotes?page=${page}&limit=${limit}`)
    }
    if (pathname.includes("orders"))
      return navigate(`/orders/${quoteDetail?.id || 0}`)
    if (pathname.includes("checkout"))
      return navigate(`/new-quote/checkout/${quoteDetail?.id || 0}/submitted`)
  }

  if (!data || isFetchingFile || isFetching || isFetchingOrder || !orderData)
    return <></>

  return (
    <RootContainer
      onBack={isAuthenciated ? handleBack : undefined}
      headerText="Quote Details"
      customRightElement={
        <Button
          customType={Type.NEUTRAL}
          hierarchy={ButtonHierarchy.OUTLINE}
          customSize={Size.LARGE}
          customClassName="invoice-btn"
          onClick={() => {
            window.open(
              quoteDetail?.pdf_url.replace(
                DIGIFABSTER_INVOICE_URL,
                safeConcatUrl(
                  String(process.env.REACT_APP_BACKEND_ENDPOINT),
                  "invoice"
                ) || ""
              ) || "",
              "_blank"
            )
          }}
        >
          <img src={DownloadIcon} alt="" />
          Download Quote
        </Button>
      }
    >
      <div className="quotes-detail">
        <div className="quotes-detail-left">
          <div className="quotes-detail-left-content">
            <div className="quotes-detail-left-content-detail">
              <div className="quotes-detail-left-content-detail-header">
                <div className="quotes-detail-left-content-detail-header-wrapper">
                  <div className="quotes-detail-left-content-detail-header-title">
                    <div className="quotes-detail-left-content-detail-header-img">
                      <img src={quoteForgeLabIcon} alt="" />
                    </div>
                    <p>
                      Your quote <span>#{quoteDetail?.company_order_id}</span>{" "}
                      is here!
                    </p>
                    <p className="quotes-detail-left-content-detail-header-title-subtext">
                      If you have any questions about your quote, please contact
                      us directly at {PHONE_NUMBER_CONTACT} or email{" "}
                      <span>
                        {quoteDetail?.internal_owner
                          ? quoteDetail?.internal_owner?.email
                          : "sales@forgelabs.com"}
                      </span>
                    </p>
                  </div>
                  <div className="quotes-detail-left-content-detail-header-info">
                    <p className="quotes-detail-left-content-detail-header-info-text-title">
                      ORDER DETAILS
                    </p>
                    <div className="quotes-detail-left-content-detail-header-info-detail">
                      <div className="quotes-detail-left-content-detail-header-info-detail-left">
                        <p>Order Status</p>
                        <CustomBadge
                          content={quoteDetail?.status_display as string}
                          type={BadgeType.TONAL}
                          size={Size.MEDIUM}
                          color={getColorBagde(quoteDetail?.status as string)}
                        />
                      </div>
                      <div className="quotes-detail-left-content-detail-header-info-detail-right">
                        <p>Order Date</p>
                        <p>{createdAt}</p>
                      </div>
                    </div>
                    <div className="quotes-detail-left-content-detail-header-info-detail">
                      <div className="quotes-detail-left-content-detail-header-info-detail-left">
                        <p>Delivery Type</p>
                        {quoteDetail?.self_notes ? (
                          <p>
                            {deliveryTypeText(
                              getTextShippingMethod(quoteDetail?.self_notes)
                            )}
                          </p>
                        ) : (
                          "-"
                        )}
                      </div>
                      <div className="quotes-detail-left-content-detail-header-info-detail-right">
                        <p>Total</p>
                        <p>
                          {currency}
                          {priceDisplay(
                            Number(
                              (quoteDetail?.total_price || 0) - waitingPrice
                            )
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="quotes-detail-left-content-detail-header-info-detail">
                      <div className="quotes-detail-left-content-detail-header-info-detail-right">
                        <p>Estimated Completion</p>
                        <p className="quotes-detail-left-content-detail-header-info-detail-right-estimated">
                          {calcRangeDateAfter7Days(
                            range,
                            quoteDetail?.line_items,
                            quoteDetail?.date || ""
                          )}
                        </p>
                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="quotes-detail-left-content-info">
                <div className="quotes-detail-left-content-info-address">
                  <div className="quotes-detail-left-content-info-address-shipping">
                    <p className="quotes-detail-left-content-info-address-title">
                      Shipping Address
                    </p>
                    <div className="quotes-detail-left-content-info-address-shipping-detail">
                      <p>
                        {order?.shipping_address?.company_name &&
                        order?.shipping_address?.company_name !== "blank"
                          ? order?.shipping_address?.company_name
                          : ""}
                      </p>
                      <p>
                        {" "}
                        {order?.shipping_address?.street_address || ""}
                        {order?.shipping_address?.apartment &&
                          `, ${order?.shipping_address?.apartment || ""}`}
                      </p>
                      <p>
                        {order?.shipping_address?.city || ""}
                        {order?.shipping_address?.state &&
                          `, ${order?.shipping_address?.state || ""}`}
                      </p>
                      <p>
                        {order?.shipping_address?.country || ""}
                        {order?.shipping_address?.postcode &&
                          `, ${order?.shipping_address?.postcode || ""}`}
                      </p>
                    </div>
                  </div>
                  {hideBillingAddress && (
                    <div className="quotes-detail-left-content-info-address-billing">
                      <p className="quotes-detail-left-content-info-address-title">
                        Billing Address
                      </p>
                      <div className="quotes-detail-left-content-info-address-billing-detail">
                        {order?.billing_address?.company_name && (
                          <p>{order.billing_address.company_name}</p>
                        )}
                        {order?.billing_address?.street_address !== "none" &&
                          order?.billing_address?.street_address && (
                            <p>
                              {order.billing_address.street_address}
                              {order?.billing_address?.apartment &&
                                `, ${order.billing_address.apartment}`}
                            </p>
                          )}

                        {order?.billing_address?.city !== "none" &&
                          order?.billing_address?.city && (
                            <p>
                              {order.billing_address.city}
                              {order?.billing_address?.state &&
                              order.billing_address.state !== "none"
                                ? `, ${order.billing_address.state}`
                                : ""}
                            </p>
                          )}

                        {order?.billing_address?.country && (
                          <p>
                            {order.billing_address.country}
                            {order?.billing_address?.postcode &&
                            order.billing_address.postcode !== "none"
                              ? `, ${order.billing_address.postcode}`
                              : ""}
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="quotes-detail-left-content-detail-product">
              <div className="quotes-detail-left-content-detail-product-header">
                <p>MODEL</p>
                <p>SPECIFICATIONS</p>
                <p>POST PRODUCTION</p>
                <p>QUANTITY</p>
                <p>TOTAL</p>
              </div>
              <div className="quotes-detail-left-content-detail-product-list">
                {product?.map((item) => (
                  <QuoteItem
                    currency={currency}
                    key={item.id}
                    item={item}
                    deliveryDays={getLatestDeliveryDate(
                      quoteDetail?.line_items
                    )}
                  />
                ))}
                {!!order?.price_corrections?.length && (
                  <div className="quotes-detail-left-content-detail-additional">
                    <div className="quotes-detail-left-content-detail-additional-service">
                      <p className="quotes-detail-left-content-detail-additional-service-title">
                        Additional Services
                      </p>
                      {order?.price_corrections?.map((item) => (
                        <div className="quotes-detail-left-content-detail-additional-service-detail">
                          <div className="quotes-detail-left-content-detail-additional-service-detail-info">
                            <p>{item.comment ? item.comment : "-"}</p>
                            <p>
                              + {currency}{" "}
                              {priceDisplay(Number(item.value) || 0)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {order?.notes && (
                  <div className="quotes-detail-left-content-note">
                    <div className="quotes-detail-left-content-note-header">
                      <img src={NotesIcon} alt="" />
                      <p>Add Order Notes</p>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${order?.notes}`
                      }}
                    ></div>
                  </div>
                )}
                {!!fileData?.length && (
                  <div className="quotes-detail-left-content-files">
                    {fileData?.map((item: any) => (
                      <div
                        key={item.id}
                        className="quotes-detail-left-content-files-item"
                      >
                        <p>{item.name}</p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="quotes-detail-right">
          <QuoteSummary waitingPrice={waitingPrice} currency={currency} />
        </div>
      </div>
    </RootContainer>
  )
}

export default QuoteDetailPage
