import { IPostProductionData } from "@/interfaces/editSpecifications.interface"
import {
  GetPricePostProductionResponse,
  LineItem,
  Product,
  UploadDrawingsResponse,
  useDeleteDrawingsMutation,
  useGetPricePostProductionMutation,
  useGetPriceProductMutation,
  useUploadDrawingsMutation
} from "@/services/apiDigifabster/quote"
import {
  IAvailableTechnology,
  IColor,
  ILeadTime,
  IMaterial,
  IMaterialConfig,
  ITechnology
} from "@/store/product"
import { debounce } from "@/utils/functionHelper"
import { replacePostProductionTitle } from "@/utils/stringHelper"
import { UploadFile } from "antd"
import { RcFile } from "antd/es/upload"
import { useState } from "react"
import * as toast from "@/utils/Toast"

interface IPrefix {
  technology: string
  color: string
}
export const useEditSpecification = () => {
  const [getPriceProduct] = useGetPriceProductMutation()
  const [getPricePostProduction] = useGetPricePostProductionMutation()
  const [uploadDrawings, { isLoading: isUploadingFile }] =
    useUploadDrawingsMutation()
  const [deleteDrawings, { isLoading: isDeleteFile }] =
    useDeleteDrawingsMutation()

  const [isPriceLoading, setIsPriceLoading] = useState<boolean>(false)
  const [allTechnologies, setAllTechnologies] = useState<ITechnology[]>([])
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [materials, setMaterials] = useState<IMaterial[]>([])
  const [colors, setColors] = useState<IColor[]>([])
  const [layerHeights, setLayerHeights] = useState<IMaterialConfig[]>([])
  const [inFills, setInFills] = useState<IMaterialConfig[]>([])
  const [postProduction, setPostProduction] = useState<
    Record<string, IMaterialConfig[]>
  >({} as Record<string, IMaterialConfig[]>)
  const [leadTimes, setLeadTimes] = useState<ILeadTime[]>([])
  const [currentPrefix, setCurrentPrefix] = useState<IPrefix>({
    technology: "",
    color: ""
  })
  const [disable, setIsDisable] = useState<boolean>(false)

  const getPrices = ({
    watchMaterial,
    watchLayerHeight,
    watchColor,
    watchLeadTime,
    watchFiling,
    watchQuanity,
    watchPostProduction,
    product
  }: {
    watchMaterial: number
    watchLayerHeight: string
    watchColor: string
    watchLeadTime: string
    watchFiling: string
    watchQuanity: number
    watchPostProduction?: IPostProductionData[]
    product?: Product
  }) => {
    if (
      !watchMaterial ||
      !watchLayerHeight ||
      !watchColor ||
      !watchLeadTime ||
      !watchFiling ||
      !watchQuanity ||
      !product
    )
      return
    const config:
      | Record<string, string | string>
      | Record<string, { uuid: string }[]> = {}
    config["layer_thickness"] = watchLayerHeight
    config["filling"] = watchFiling
    if (watchColor) config["color"] = watchColor
    if (watchPostProduction)
      config["post_production"] = watchPostProduction?.map((e) => {
        return { uuid: e.uuid, quantity: e.quantity }
      })
    debouncedPrice(config, product, watchMaterial, watchQuanity, watchLeadTime)
  }

  const debouncedPrice = debounce(
    async (config, product, material_id, count, lead_time) => {
      setIsPriceLoading(true)
      await getPriceProduct({
        config,
        model_id: product.model_id,
        material_id,
        count: [count],
        lead_time: [lead_time]
      })
      setIsPriceLoading(false)
    },
    500
  )

  const getPostProductionList = async (
    material: IMaterial,
    country: string,
    product: Product
  ) => {
    const isProductMaterial = product.material_id === material.id

    const { data: prices } = await getPricePostProduction({
      model_id: product.id,
      material_id: material.id
    })
    if (!prices) return {} as Record<string, IMaterialConfig[]>
    const postProductionData = material.post_production
      .filter((m) => {
        if (country === "CA") return !m.title.includes("-")
        return m.title.includes(`${country}`)
      })
      .map((e) => {
        const postProductionPrice = prices.items.find((p) => p.uuid === e.uuid)
        return {
          ...e,
          title: replacePostProductionTitle(e.title, country),
          price: postProductionPrice?.price_per_item || 0
        }
      })
    const postProductionList: Record<string, IMaterialConfig[]> =
      postProductionData.reduce((acc, item) => {
        const groupKey = item.group_title || "Post Production"
        if (!acc[groupKey]) {
          acc[groupKey] = []
        }
        acc[groupKey].push(item)
        return acc
      }, {} as Record<string, IMaterialConfig[]>)
    setPostProduction(postProductionList)
    const prefillPostProduction = isProductMaterial
      ? product?.config?.post_production
      : []
    return { prefillPostProduction }
  }

  const getTextPlaceHolder = (item: string) => {
    if (item === "Post Production") return "Standard Finish"
    if (item === "Inspection Criteria") return "Visual Inspection"
    return item
  }

  const handlePrefillMaterials = (
    suitMaterialsMultiple: IAvailableTechnology[],
    suitableMaterial: Record<number, IAvailableTechnology[]>,
    product: Product,
    techId: number,
    isMultiple?: boolean,
    tech?: ITechnology
  ) => {
    setCurrentPrefix((prev) => ({ ...prev, technology: tech?.image || "" }))

    const suitMaterials = isMultiple
      ? suitMaterialsMultiple?.find((e) => e.id === techId)?.materials || []
      : suitableMaterial[product.parent_model_id]?.find((e) => e.id === techId)
          ?.materials || []
    const mappedMeterials =
      suitMaterials.map((e) => {
        return tech?.materials?.find((m) => m.id === e) || ({} as IMaterial)
      }) || []
    if (!tech) return { material: 0 }

    setMaterials(mappedMeterials)
    setIsDisable(false)
    const isPrefillMaterial = mappedMeterials?.some(
      (e) => e.id === product.material_id
    )
    const material =
      isPrefillMaterial && !isMultiple
        ? product?.material_id
        : mappedMeterials[0]?.id
    return { material }
  }

  const handlePrefillData = (product: Product, material: IMaterial) => {
    const isProductMaterial = product?.material_id === material.id

    const colorList = material.color
    setColors(colorList || [])

    const layerHeightList = material.layerThickness
    setLayerHeights(layerHeightList || [])

    const leadTimeList = material.leadTime
    setLeadTimes(leadTimeList || [])

    const fillingList = material.filling
    setInFills(fillingList || [])

    const prefillQuantity = isProductMaterial ? product?.count : 1

    return {
      isProductMaterial,
      prefillQuantity,
      colorList,
      layerHeightList,
      leadTimeList,
      fillingList
    }
  }

  const handlePrefillTechnology = (
    technologies: ITechnology[],
    product: Product,
    suitMaterialsMultiple: IAvailableTechnology[],
    selectedItems?: LineItem[],
    isMultiple?: boolean
  ) => {
    const prefillFiles =
      (product.drawing_files as unknown as UploadFile[]) || []
    setFileList(prefillFiles)
    const suitableTech = technologies.filter((e) =>
      suitMaterialsMultiple?.some((item) => item.id === e.id)
    )
    if (isMultiple && selectedItems?.length) {
      setAllTechnologies(suitableTech)
    } else {
      setAllTechnologies(technologies)
    }
    const material = isMultiple
      ? suitableTech?.[0]?.materials?.[0]?.id
      : product.material_id
    const selectedTech = technologies.find((e) =>
      e.materials.find((m) => m.id === material)
    )
    if (!selectedTech) return { suitableTech, material, selectedTech }
    setMaterials(selectedTech?.materials)
    setCurrentPrefix((prev) => ({
      ...prev,
      technology: selectedTech?.image || ""
    }))

    const selectedMaterial = selectedTech.materials.find(
      (e) => e.id === material
    )
    if (!selectedMaterial) return { suitableTech, material, selectedTech }
    setColors(selectedMaterial?.color || [])
    setLayerHeights(selectedMaterial?.layerThickness || [])
    setLeadTimes(selectedMaterial?.leadTime || [])

    return { suitableTech, material, selectedTech }
  }

  const setColorPrefix = (color: string) => {
    const currentColor = colors.find((e) => e.uuid === color)
    setCurrentPrefix((prev) => ({
      ...prev,
      color: currentColor?.color || ""
    }))
  }

  const submitFile = async (
    info: {
      file: UploadFile
      fileList: UploadFile[]
    },
    order_id: number,
    purchase_id: number
  ) => {
    const uploadingFiles = info.fileList
      ?.filter((file, index) => {
        return index === info.fileList?.length - 1
      })
      .map((e) => {
        if (e.uid === info.file.uid) {
          return { ...e, status: "uploading", size: 0 } as UploadFile
        }
        return e
      })

    setFileList(uploadingFiles)
    debounceMultipleUpload(info.file, order_id, purchase_id)
  }

  const removeFile = async (
    file: UploadFile,
    order_id: number,
    purchase_id: number
  ) => {
    if (file.uid && !file.url) {
      setFileList((pre) => pre.filter((e) => e.uid !== file.uid))
    } else {
      if (!order_id || !purchase_id) return
      const fileTrans = file as unknown as UploadDrawingsResponse

      const data = await deleteDrawings({
        order_id,
        purchase_id,
        drawings_id: Number(fileTrans.id)
      })
      if (!data) return toast.showError("Failed to delete file")
      setFileList((prev) =>
        prev.filter(
          (e) => (e as unknown as UploadDrawingsResponse).id !== fileTrans.id
        )
      )
    }
  }

  const handleSubmitFile = async (
    file: UploadFile,
    order_id: number,
    purchase_id: number
  ) => {
    try {
      const uploadPayload = new FormData()
      uploadPayload.append("file", file as RcFile, file.name)

      const res = await uploadDrawings({
        order_id,
        purchase_id,
        arg: uploadPayload
      })
      const modelsData = res?.data
      if (!modelsData) throw new Error()

      const resData = modelsData as unknown as UploadFile

      const fileObj: Record<string, UploadFile> = {
        [file.uid]: { ...resData, status: undefined, size: 0 }
      }
      setFileList((pre) => {
        return pre.map<UploadFile>((e) => fileObj[e.uid] || e)
      })
    } catch (_) {
      const fileObjFail: Record<string, UploadFile> = {
        [file.uid]: { ...file, status: "error", size: 0, name: file.name }
      }
      setFileList((pre) => {
        return pre.map<UploadFile>((e) => fileObjFail[e.uid] || e)
      })
    }
  }

  const debounceMultipleUpload = debounce(
    (uploadFiles: UploadFile, order_id: number, purchase_id: number) => {
      handleSubmitFile(uploadFiles, order_id, purchase_id)
    },
    200
  )

  return {
    getPostProductionList,
    debouncedPrice,
    getPrices,
    getTextPlaceHolder,
    handlePrefillMaterials,
    handlePrefillTechnology,
    setColorPrefix,
    handlePrefillData,
    submitFile,
    removeFile,
    isPriceLoading,
    isUploadingFile,
    isDeleteFile,
    allTechnologies,
    fileList,
    materials,
    colors,
    layerHeights,
    inFills,
    postProduction,
    leadTimes,
    currentPrefix,
    disable
  }
}
