import { api } from "../../apiInstance"
import {
  GetModelsDetailApiArg,
  GetModelsDetailApiResponse,
  GetModelsStatusPayload,
  GetModelsStatusResponse,
  ResizeModelPayload,
  ResizeModelResponse,
  SuitableMaterialsResponse,
  UploadModalPayload,
  UploadModalsResponse,
  WallThicknessPayload,
  WallThicknessResponse
} from "./type"

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getModelsDetail: build.query<
      GetModelsDetailApiResponse,
      GetModelsDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/api/dgfc/product/models/`,
        params: {
          fields:
            "file_model_viewer_url,parent_model,id,title,size,volume,units,is_processable,geometry_type,thumb,thumb_120x120,thumb_300x300,thumb_status,cnc_features,child_models",
          ...queryArg,
          limit: queryArg.limit || 100,
          format: "json"
        }
      })
    }),

    uploadModals: build.mutation<
      UploadModalsResponse[],
      UploadModalPayload | FormData
    >({
      query: (argPayload) => ({
        url: `/api/dgfo/upload_models/`,
        method: "POST",
        body: argPayload
      })
    }),
    uploadModalsDGF: build.mutation<
      UploadModalsResponse[],
      UploadModalPayload | FormData
    >({
      query: (argPayload) => ({
        url: `${process.env.REACT_APP_DGF_DOMAIN}/upload_models/`,
        method: "POST",
        body: argPayload
      })
    }),

    getModelsStatus: build.query<
      GetModelsStatusResponse,
      GetModelsStatusPayload
    >({
      query: (argPayload) => ({
        url: `/api/dgfo/model_status/`,
        method: "GET",
        params: {
          model_id: argPayload.models,
          format: "json"
        }
      })
    }),

    getSuitableMaterials: build.mutation<SuitableMaterialsResponse, number[]>({
      query: (argPayload) => ({
        url: `/api/dgfo/suitable_materials/`,
        method: "POST",
        body: {
          models_ids: argPayload
        }
      })
    }),

    checkWallThickness: build.mutation<
      WallThicknessResponse,
      WallThicknessPayload
    >({
      query: (argPayload) => {
        return {
          url: `/api/dgfo/wall_thickness/`,
          method: "POST",
          body: argPayload
        }
      }
    }),

    resizeModel: build.mutation<ResizeModelResponse, ResizeModelPayload>({
      query: (argPayload) => {
        return {
          url: `/api/dgfc/product/${argPayload.orderId}/resize-models/${argPayload.modelId}/`,
          method: "POST",
          body: argPayload.arg
        }
      }
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as modelService }

export const {
  useGetModelsDetailQuery,
  useLazyGetModelsDetailQuery,
  useUploadModalsMutation,
  useUploadModalsDGFMutation,
  useLazyGetModelsStatusQuery,
  useGetSuitableMaterialsMutation,
  useCheckWallThicknessMutation,
  useResizeModelMutation
} = injectedRtkApi
