import React from "react"
import { Button, Modal } from "@/components"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import "./ModalSupportFiles.scss"

interface IModalSupportFileProps {
  openModal: boolean
  closeModal: () => void
  onLeave?: () => void
}

function ModalSupportFiles({
  openModal,
  closeModal,
  onLeave
}: IModalSupportFileProps) {
  return (
    <Modal
      className="custom-modal-support"
      open={openModal}
      onCancel={closeModal}
      title="Unsupported Filetype"
      footer={false}
      width={401}
    >
      <div className="custom-modal-support-text-box">
        <p className="custom-modal-support-title">
          You can still request a manual review however instant pricing will
          become unavailable
        </p>
        <br />
        <p>Accepted file types :</p>
        <p>STL, OBJ, WRL,STEP, STP, IGES, IGS, 3MF, DXF, DWG</p>
      </div>
      <div className="custom-modal-support-button">
        <Button
          customSize={Size.LARGE}
          customType={Type.NEUTRAL}
          hierarchy={ButtonHierarchy.OUTLINE}
          onClick={onLeave}
        >
          Continue
        </Button>
        <Button onClick={closeModal} customSize={Size.LARGE}>
          Go Back
        </Button>
      </div>
    </Modal>
  )
}

export default ModalSupportFiles
